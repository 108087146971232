import { chakra, HTMLChakraProps } from '@chakra-ui/react'
import * as React from 'react'

interface LogoProps extends HTMLChakraProps<'svg'> {
    iconColor?: string
}

export const SaveTime = (props: LogoProps) => {
    const { ...rest } = props
    return (
        <chakra.svg
            width={12}
            height={12}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M21.5 16c-.82 0-1.5.68-1.5 1.5v9.38c0 .7.36 1.34.94 1.7l7.28 4.48a1.427 1.427 0 101.48-2.44L23 26.6v-9.1c0-.82-.68-1.5-1.5-1.5z"
                fill="#000"
            />
            <path
                d="M35.84 24A13.915 13.915 0 0122 40c-7.8 0-14-6.2-14-14s6.2-14 14-14c1.4 0 2.74.2 4 .58V8.46c-1.28-.3-2.62-.46-4-.46C12 8 4 16 4 26s8 18 18 18a17.927 17.927 0 0017.88-20h-4.04z"
                fill="#000"
            />
            <path
                d="M44 10h-4V6c0-1.1-.9-2-2-2s-2 .9-2 2v4h-4c-1.1 0-2 .9-2 2s.9 2 2 2h4v4c0 1.1.9 2 2 2s2-.9 2-2v-4h4c1.1 0 2-.9 2-2s-.9-2-2-2z"
                fill="#000"
            />
        </chakra.svg>
    )
}