import { chakra, HTMLChakraProps } from '@chakra-ui/react'
import * as React from 'react'

interface LogoProps extends HTMLChakraProps<'svg'> {
    iconColor?: string
}

export const StandardisedData = (props: LogoProps) => {
    const { ...rest } = props
    return (
        <chakra.svg
            width={12}
            height={12}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M40 12c0-4.336-7.326-8-16-8S8 7.664 8 12v4c0 4.336 7.326 8 16 8s16-3.664 16-8v-4zM24 38c-8.674 0-16-3.664-16-8v6c0 4.336 7.326 8 16 8s16-3.664 16-8v-6c0 4.336-7.326 8-16 8z"
                fill="#000"
            />
            <path
                d="M40 20c0 4.336-7.326 8-16 8S8 24.336 8 20v6c0 4.336 7.326 8 16 8s16-3.664 16-8v-6z"
                fill="#000"
            />
        </chakra.svg>
    )
}