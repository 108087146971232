import { chakra, HTMLChakraProps } from '@chakra-ui/react'
import * as React from 'react'

interface LogoProps extends HTMLChakraProps<'svg'> {
    iconColor?: string
}

export const UpToDate = (props: LogoProps) => {
    const { ...rest } = props
    return (
        <chakra.svg
            width={12}
            height={12}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M31.5 4A4.5 4.5 0 0136 8.5v31a4.5 4.5 0 01-4.5 4.5h-15a4.5 4.5 0 01-4.5-4.5v-31A4.5 4.5 0 0116.5 4h15zM24 14.06l-.204.016-.194.04a1.502 1.502 0 00-1.086 1.22l-.016.226v13.42l-1.922-1.92-.17-.146a1.5 1.5 0 00-2.098 2.1l.146.168 4.484 4.482.104.096.11.082.152.092.224.094.192.05.184.022h.188l.184-.022.126-.03.2-.072.122-.06.134-.082.124-.1.076-.07 4.484-4.482.146-.17a1.5 1.5 0 00.014-1.764l-.16-.188-.168-.146a1.5 1.5 0 00-1.766-.014l-.188.16-1.924 1.92.002-13.42-.014-.204a1.5 1.5 0 00-1.282-1.282L24 14.06z"
                fill="#000"
            />
        </chakra.svg>
    )
}