import {
  Box,
  Heading,
  SimpleGrid,
  Stack,
  useColorModeValue as mode,
  HStack,
  Button,
  Flex,
  Tag,
  Spinner,
} from '@chakra-ui/react'
import * as React from 'react'
import Feature from './Features/Feature'
import UnderlineLink from 'pages/home/utils/UnderlineLink'
import { SaveTime } from 'components/icons/SaveTime'
import { StandardisedData } from 'components/icons/StandardisedData'
import { UpToDate } from 'components/icons/UpToDate'
import { CodeBlock, dracula } from 'react-code-blocks'

const data = {
  request: {
    appId: 'd265ce22-e00e...',
    secretKey: 'a2e1903388dc...',
    accountId: '5b22ebbd-17b6...',
  },
  response: {
    balance: {
      request_id: '4496828a-1b9a-4cea-81eb-b4e7183fefb0',
      data: [
        {
          currency: 'SOL',
          amount: 43.344,
        },
        {
          currency: 'FET',
          amount: 2.3445,
        },
        {
          currency: 'RAI',
          amount: 45.222,
        },
        {
          currency: 'AVAX',
          amount: 100.323,
        },
      ],
    },
    transaction: {
      request_id: '48c6a47f-e16e-4641-85cb-20ca81fe4bf3',
      data: [
        {
          id: '45a5230d-a1bb-5e2a-8606-6f7afef3ef0a',
          status: 'completed',
          type: 'withdrawal',
          amount: {
            amount: -0.00486574,
            currency: 'BTC',
          },
          command_at: '2018-11-10T02:31:28Z',
        },
        {
          id: '91386553-394c-5107-9db7-e4cd31a5ac30',
          status: 'completed',
          type: 'withdrawal',
          amount: {
            amount: 0.000755,
            currency: 'BTC',
          },
          command_at: '2017-12-22T03:23:57Z',
        },
        {
          id: '42a1aef4-3086-5d84-a0b3-3bda6665359b',
          status: 'completed',
          type: 'trade',
          amount: {
            amount: 0.00400478,
            currency: 'BTC',
          },
          command_at: '2017-12-15T11:07:16Z',
        },
        {
          id: '169b09f1-fdaf-5d10-b0e8-a5ca29f29e7e',
          status: 'completed',
          type: 'trade',
          amount: {
            amount: -0.0083318,
            currency: 'BTC',
          },
          command_at: '2017-12-08T05:28:54Z',
        },
        {
          id: '8a24f54b-b6d0-55d1-b077-3906503e8e45',
          status: 'completed',
          type: 'withdrawal',
          amount: {
            amount: 0.00456221,
            currency: 'BTC',
          },
          command_at: '2017-12-06T14:32:40Z',
        },
        {
          id: 'e254563b-df2a-5509-ade5-0134ffd3ac14',
          status: 'completed',
          type: 'trade',
          amount: {
            amount: 0.00358483,
            currency: 'BTC',
          },
          command_at: '2017-11-29T03:56:20Z',
        },
        {
          id: '29ca753b-1b84-5eef-b221-065792a74ca1',
          status: 'completed',
          type: 'trade',
          amount: {
            amount: -0.01504016,
            currency: 'BTC',
          },
          command_at: '2017-10-31T02:41:08Z',
        },
        {
          id: '54809548-37d4-56fb-ae85-e4267a020e3e',
          status: 'completed',
          type: 'trade',
          amount: {
            amount: 0.01284988,
            currency: 'BTC',
          },
          command_at: '2017-10-15T11:44:21Z',
        },
        {
          id: '714dc0eb-cad2-5145-bf30-aa8a296ee58b',
          status: 'completed',
          type: 'withdrawal',
          amount: {
            amount: 0.002481,
            currency: 'BTC',
          },
          command_at: '2017-09-16T09:41:10Z',
        },
      ],
    },
  },
}

export default function Features() {
  const [loading, setLoading] = React.useState(false)
  const [view, setView] = React.useState<{
    type: 'balance' | 'transaction'
    isShowData: boolean
  }>({ type: 'balance', isShowData: false })

  const switchView = React.useCallback(
    (type: 'balance' | 'transaction') => {
      setView({ type, isShowData: false })
    },
    [setView]
  )

  const jsonData = React.useMemo(() => {
    if (view.isShowData) {
      return JSON.stringify(
        view.type === 'balance'
          ? data.response.balance
          : data.response.transaction,
        undefined,
        4
      )
    } else {
      return JSON.stringify(data.request, undefined, 4)
    }
  }, [view])

  const run = React.useCallback(() => {
    setLoading(true)
    setTimeout(() => {
      setView({ ...view, isShowData: true })
      setLoading(false)
    }, 2000)
  }, [view, setView])

  return (
    <Box
      as="section"
      bg={mode('gray.50', 'gray.800')}
      pt="16"
      pb="32"
      id="features"
    >
      <Box
        maxW={{ base: 'xl', md: '7xl' }}
        mx="auto"
        px={{ base: '6', md: '8' }}
      >
        <Heading
          textAlign="center"
          letterSpacing="tight"
          fontWeight="extrabold"
        >
          Spend less time integrating, go to{' '}
          <UnderlineLink>market faster.</UnderlineLink>
        </Heading>
        <Box mt="24">
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            spacing={{ base: '16', md: '8' }}
          >
            <Stack spacing="12" maxW="lg">
              <Feature
                icon={<SaveTime />}
                title="Save time"
                iconDarkMode="gray.400"
                iconLightMode="gray.700"
                textDarkMode="gray.400"
                textLightMode="gray.600"
              >
                Integrating exchanges, wallets and various blockchains takes
                months of development time. Walawong integration takes a few
                hours.
              </Feature>
              <Feature
                icon={<UpToDate />}
                title="Always up to date"
                iconDarkMode="gray.400"
                iconLightMode="gray.700"
                textDarkMode="gray.400"
                textLightMode="gray.600"
              >
                We are constantly adding exchanges, wallets and blockchain,
                which means you will always have the latest for your users
                without the need to update anything.
              </Feature>
              <Feature
                icon={<StandardisedData />}
                title="Standardised Data"
                iconDarkMode="gray.400"
                iconLightMode="gray.700"
                textDarkMode="gray.400"
                textLightMode="gray.600"
              >
                We make every connection return the exact same data format,
                giving your application unmatched reliability and the
                consistency you want.
              </Feature>
            </Stack>
            <Flex
              shadow="lg"
              borderRadius="lg"
              overflow="hidden"
              flexDirection="column"
              h="md"
            >
              <HStack bg="brand.700" w="full" p="3">
                <Button
                  size="sm"
                  colorScheme="gray"
                  disabled={loading}
                  onClick={() => switchView('balance')}
                >
                  Get Balances
                </Button>
                <Button
                  size="sm"
                  colorScheme="gray"
                  disabled={loading}
                  onClick={() => switchView('transaction')}
                >
                  Get Transaction
                </Button>
              </HStack>
              <Flex
                flex="1"
                bg="#282a36"
                flexDirection="column"
                overflowY="auto"
              >
                <Heading size="md" color="white" py="4" px="4">
                  Get {view.type === 'balance' ? 'balances' : 'transactions'}
                </Heading>
                <CodeBlock
                  text={jsonData}
                  language="json"
                  showLineNumbers={false}
                  theme={dracula}
                />
              </Flex>
              <Flex
                bg="brand.700"
                w="full"
                p="3"
                justifyContent="space-between"
                alignItems="center"
              >
                <Tag colorScheme="blue" size="sm" h="5">
                  json
                </Tag>
                <Button
                  colorScheme="gray"
                  minW="28"
                  leftIcon={loading ? <Spinner /> : undefined}
                  disabled={loading}
                  onClick={run}
                >
                  Run
                </Button>
              </Flex>
            </Flex>
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  )
}
